<template>
    <div>
        <el-form :model="form" :rules="formRules" ref="formRef" label-position="">

            <el-form-item label="文章标题" prop="title">
                <el-input v-model="form.title" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="展示图" required class="upload">
                <el-upload
                        action="http://api.yonnxu.cn/ab"
                        :show-file-list="false"
                        class="middle_list"
                        list-type="picture-card"
                        :on-success="handlePictureSuccess"
                        :before-upload="beforeAvatarUpload"
                        ref="upload">
                    <img v-if="form.firstPicture" :src="form.firstPicture" class="avatar" alt="">
                    <i v-else class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="文章描述" prop="description">
                <mavon-editor v-model="form.description" class="markdown-body"/>
            </el-form-item>

            <el-form-item label="文章正文" prop="content">
                <mavon-editor v-model="form.content" class="markdown-body"/>
            </el-form-item>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="分类" prop="cate">
                        <el-select v-model="form.cate" placeholder="请选择分类"
                                   :filterable="true" style="width: 100%;">
                            <el-option :label="item.name" :value="item.id" v-for="item in categoryList"
                                       :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="标签" prop="tagList">
                        <el-select v-model="form.tagList" placeholder="请选择标签"
                                   :filterable="true" :multiple="true" style="width: 100%;">
                            <el-option :label="item.tag_name" :value="item.id" v-for="item in tagList"
                                       :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>


            <el-form-item style="text-align: right;">
                <el-button type="primary" @click="dialogVisible=true">保存</el-button>
            </el-form-item>
        </el-form>

        <!--编辑可见性状态对话框-->
        <el-dialog title="确认操作" width="30%" :visible.sync="dialogVisible">
            <!--底部-->
            <span slot="footer">
				<el-button @click="dialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="submit">保存</el-button>
			</span>
        </el-dialog>
    </div>
</template>

<script>
    import {getCategoryAndTag, saveArticle, getArticleById, updateArticle} from '@/api/blog'

    export default {
        name: "WriteBlog",
        data() {
            return {
                categoryList: [],
                tagList: [],
                dialogVisible: false,
                radio: 1,
                form: {
                    // 标题
                    title: '',
                    // "文章首图URL"
                    firstPicture: '',
                    // "文章描述"
                    description: '',
                    // "文章正文"
                    content: '',
                    // 分类
                    cate: null,
                },
                formRules: {
                    title: [{required: true, message: '请输入标题', trigger: 'change'}],
                    cate: [{required: true, message: '请选择分类', trigger: 'change'}],
                    tagList: [{required: true, message: '请选择标签', trigger: 'change'}],
                },
            }
        },
        created() {
            this.getData()
            if (this.$route.params.id) {
                this.getArticle(this.$route.params.id)
            }
        },
        methods: {
            handlePictureSuccess(res, file) {
                fetch(file.url).then(data => {
                    const blob = data.blob()
                    return blob;
                }).then(blob => {
                    let reader = new window.FileReader()
                    // 使用bind改变指针
                    reader.onloadend = function () {
                        this.form.firstPicture = reader.result
                        // file.watch = this.conditions.watch
                    }.bind(this)
                    reader.readAsDataURL(blob)
                });
            },
            beforeAvatarUpload(file) {
                const isIMG = file.type === 'image/jpeg';
                const isLt5M = file.size / 1024 < 100;

                if (!isIMG) {
                    this.$message.error('上传图片只能是JPG格式!');
                }
                if (!isLt5M) {
                    this.$message.error('上传图片大小不能超过100kb!');
                }
                return isIMG && isLt5M;
            },
            getData() {
                getCategoryAndTag().then(res => {
                    this.categoryList = res.data.artcate
                    this.tagList = res.data.tags
                })
            },
            getArticle(id) {
                getArticleById(id).then(res => {
                    this.computeCategoryAndTag(res.data[0])
                    this.form = res.data[0]
                })
            },
            computeCategoryAndTag(blog) {
                blog.tagList = []
                blog.tags.forEach(item => {
                    blog.tagList.push(item)
                })
            },
            submit() {
                this.$refs.formRef.validate(valid => {
                    if (valid) {
                        if (this.$route.params.id) {
                            updateArticle(this.form).then(res => {
                                this.msgSuccess(res.message)
                                this.$router.push('/blog/list')
                            })
                        } else {
                            if (!this.form.firstPicture) return this.msgError('请上传图片')
                            saveArticle(this.form).then(res => {
                                this.msgSuccess(res.message)
                                this.$router.push('/blog/list')
                            })
                        }
                    } else {
                        this.dialogVisible = false
                        return this.msgError('请填写必要的表单项')
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .avatar {
        width: 146px;
        height: 146px;
    }
</style>